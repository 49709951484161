/* Arial Regular 400 to Proxima Nova Cond Regular 400 */
@font-face {
  font-family: 'ArialToProximaNovaCond';
  font-style: regular;
  font-weight: 400;
  src: local('Arial');
  size-adjust: 82.7601%;
  ascent-override: 130.3769%;
  descent-overrride: 48.0908%;
  line-gap-override: 0%;
}
/* Arial Bold Regular 700 to Proxima Nova Cond Regular 700 */
@font-face {
  font-family: 'ArialToProximaNovaCond';
  font-style: regular;
  font-weight: 700;
  src: local('Arial Black'), local('Arial');
  size-adjust: 70.7196%;
  ascent-override: 152.5744%;
  descent-overrride: 56.2786%;
  line-gap-override: 0%;
}

/* Arial Regular 900 to Proxima Nova Cond Regular 900 */
@font-face {
  font-family: 'ArialToProximaNovaCond';
  font-style: regular;
  font-weight: 900;
  src: local('Arial Black'), local('Arial');
  size-adjust: 74.952%;
  ascent-override: 160.55%;
  descent-overrride: 53.1006%;
  line-gap-override: 0%;
}

/* Arial Regular 400 to Proxima Nova Regular 400 */
@font-face {
  font-family: 'ArialToProximaNova';
  font-style: regular;
  font-weight: 400;
  src: local('Arial');
  size-adjust: 97.924%;
  ascent-override: 110.1874%;
  descent-overrride: 40.6437%;
  line-gap-override: 0%;
}

/* Arial Regular 700 to Proxima Nova Regular 700 */
@font-face {
  font-family: 'ArialToProximaNova';
  font-style: regular;
  font-weight: 700;
  src: local('Arial Bold'), local('Arial');
  size-adjust: 94.861%;
  ascent-override: 115.5%;
  descent-overrride: 41.90%;
  line-gap-override: 0%;
}

/* Arial Black Regular 900 to Proxima Nova Regular 800 */
@font-face {
  font-family: 'ArialToProximaNova';
  font-style: regular;
  font-weight: 800;
  src: local('Arial Black'), local('Arial');
  size-adjust: 84.254%;
  ascent-override: 128.0651%;
  descent-overrride: 47.2381%;
  line-gap-override: 0%;
}

/* Arial Regular 900 to Proxima Nova Regular 900 */
@font-face {
  font-family: 'ArialToProximaNova';
  font-style: regular;
  font-weight: 900;
  src: local('Arial Black'), local('Arial Bold'), local('Arial');
  size-adjust: 87.0085%;
  ascent-override: 124.0109%;
  descent-overrride: 45.7427%;
  line-gap-override: 0%;
}